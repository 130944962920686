import React from "react";

import GenericSite from "../components/GenericSite";

const GenericSites = ({ pageContext }) => {
  const pageTitle = pageContext?.data.title ?? "Tytuł strony";
  const pageSections = pageContext?.data.sections ?? [];
  const sectionsArray =
    pageSections.map((section) => section.generic_site_sections_id) ?? [];

  return <GenericSite title={pageTitle} sections={sectionsArray} />;
};

export default GenericSites;
