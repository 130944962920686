import React from "react";

import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";

import clsx from "clsx";

import Headline from "../components/Headline";
import Layout from "../components/Layout";
import useTheme from "../hook/useTheme";

type Section = {
  id: string;
  title: string;
  text: string;
  photo?: { imageFile: ImageDataLike; description?: string };
};

type Props = {
  title: string;
  sections: Section[];
};

const GenericSite = ({ title, sections }: Props) => {
  const theme = useTheme(8);

  return (
    // @ts-ignore
    <Layout theme={theme}>
      <div className="container mx-auto px-2 pt-8 md:px-8 xl:px-16">
        <div
          className={clsx("mx-auto rounded-t-md px-3 py-5", theme.bgStyleLight)}
        >
          <Headline title={title} className="mb-4 font-bold" level={1} />
          <div className="my-6 rounded-md bg-white px-4 py-2">
            {sections.map((section) => {
              const image = section?.photo?.imageFile
                ? getImage(section?.photo?.imageFile)
                : null;

              return (
                <div key={section.id}>
                  {section.photo && image && (
                    <GatsbyImage
                      className="my-10 max-h-30-screen w-full rounded-md"
                      image={image}
                      alt={section?.photo.description ?? "brak opisu zdjęcia"}
                      imgStyle={{ width: "full", margin: "0 auto" }}
                    />
                  )}
                  <Headline
                    title={section.title}
                    level={2}
                    className="my-4 font-semibold"
                    paddingClassName="px-4"
                  />
                  <div
                    className="prose mb-4 max-w-none px-2"
                    dangerouslySetInnerHTML={{ __html: section.text }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default GenericSite;
